import { ReactElement } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { RoundHelper } from "../../../persistence/helpers/RoundHelper";
import { States } from "../../../persistence/States";
import { PlayerHelper } from "../../../persistence/helpers/PlayerHelper";
import { MatchEntry } from "./match-entry/MatchEntry";
import { ByeEntry } from "./bye-entry/ByeEntry";

export const RoundPlayingView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const round = RoundHelper.getCurrentRound(appState.tournament);
  const roundNumber = RoundHelper.getCurrentRoundNumber(appState.tournament);
  const nextRoundNumber = roundNumber + 1;

  const allMatchesDone = round.matches.every((match) => match.finished);

  function render(): ReactElement {
    return (
      <div className="page bg-in-progress">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="in-progress">
                <span className="preheader light">Round {roundNumber}</span>
                <br />
                <span>In Progress</span>
              </h1>
            </div>
            <div className="subnavigation single-right">{renderInformationMessage()}</div>
          </div>
          <div className="entries">
            {renderMatches()}
            {renderByes()}
          </div>
        </div>
        {renderDoneButton()}
      </div>
    );
  }

  function renderMatches() {
    return round.matches.map((match, i) => {
      let table = i + 1;
      return <MatchEntry key={match.id} className="pairings-entry-instance" table={table} match={match} />;
    });
  }

  function renderByes() {
    return round.byes.map((b, i) => {
      let table = round.matches.length + 1 + i;
      return <ByeEntry key={b} className="pairings-entry-instance" table={table} bye={b} />;
    });
  }

  function renderInformationMessage() {
    if (!allMatchesDone) {
      return <div className="subnav-button greyed-out">Tap Winning Players</div>;
    } else {
      return <div className="subnav-button next">All Matches Finished</div>;
    }
  }

  function renderDoneButton() {
    let className = "link greyed-out ";

    if (allMatchesDone) className = "link next";

    return (
      <div className="bottom-navigation single">
        <h1 className={className} onClick={handleStopRound}>
          <span className="preheader">Round {nextRoundNumber}</span>
          <br />
          Pairings
        </h1>
      </div>
    );
  }

  function handleStopRound(): void {
    if (!allMatchesDone) return;

    let players = appState.tournament.players;
    let round = RoundHelper.getCurrentRound(appState.tournament);

    players = RoundHelper.recordOpponents(round, players);
    players = PlayerHelper.updatePlayerScores(appState.tournament.rounds, players);

    let currentRoundNumber = RoundHelper.getCurrentRoundNumber(appState.tournament);
    let expectedRounds = RoundHelper.getExpectedRounds(appState.tournament.players.length);
    let roundLimitHit = currentRoundNumber >= expectedRounds;
    let nextState = roundLimitHit ? States.STANDINGS_FINAL : States.ROUND_PROPOSE;

    setAppState({
      state: nextState,
      tournament: {
        players: players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
