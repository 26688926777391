import { ReactElement, useState } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { Player } from "../../../persistence/model/Player";
import { PlayersEntry } from "./players-entry/PlayersEntry";
import { PlayersInput } from "./players-input/PlayersInput";
import { IoChevronBack } from "react-icons/io5";
import { getDuplicatePlayerNames } from "../../../utils/PlayerArrayUtils";
import { uuid } from "../../../utils/UuidUtils";
import { PlayerHelper } from "../../../persistence/helpers/PlayerHelper";

export const PlayersEditView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);
  const [playersState, setPlayersState] = useState<Player[]>(appState.tournament.players);

  let playerNames = new Map(playersState.map((p) => [uuid(), p.name]));
  let hasEnoughPlayers = playersState.filter((p) => !p.dropped).length >= PlayerHelper.PLAYER_AMOUNT_MINIMUM;
  let duplicatePlayerNames = getDuplicatePlayerNames(playerNames);
  let hasDuplicatePlayers = duplicatePlayerNames.length > 0;
  let droppedPlayers = playersState.filter((p) => p.dropped);

  function render(): ReactElement {
    return (
      <div className="page bg-edit">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="edit">
                Add/Drop
                <br />
                Players
              </h1>
            </div>
            <div className="subnavigation double">
              <div className="subnav-button alert link" onClick={handleDiscardTournament}>
                <IoChevronBack /> Back
              </div>
            </div>
          </div>
          <div className="entries">{renderPlayers()}</div>
          {droppedPlayers.length > 0 && renderDroppedPlayers()}
        </div>
        <div className="bottom-navigation single">{renderStartButton()}</div>
      </div>
    );
  }

  function renderPlayers() {
    let inputs: ReactElement[] = [];

    let i = 0;
    playersState.forEach((p) => {
      let lastElementStyle = i >= playersState.length ? "next" : "";
      let duplicateNameStyle = duplicatePlayerNames.includes(p.name) ? "alert" : "";

      if (!p.dropped) {
        inputs.push(
          <PlayersEntry
            className={`${duplicateNameStyle} ${lastElementStyle}`}
            key={p.id}
            index={i}
            player={p}
            onPlayerRemove={handlePlayerDrop}
          />
        );
        i++;
      }
    });

    inputs.push(<PlayersInput className="" index={i} key={i} players={playersState} onPlayerAdd={handlePlayerAdd} />);
    return inputs;
  }

  function renderDroppedPlayers(): ReactElement {
    return (
      <div className="entries">
        <div className="subnav-button greyed-out">Dropped players</div>
        {droppedPlayers.map((p, i) => {
          return <PlayersEntry key={p.id} className="entry" index={i} player={p} onPlayerRemove={handlePlayerDrop} />;
        })}
      </div>
    );
  }

  function renderStartButton() {
    let className = `link greyed-out `;

    if (!hasDuplicatePlayers && hasEnoughPlayers) className += "next";

    return (
      <h1 className={className} onClick={handleSaveTournament}>
        Done
      </h1>
    );
  }

  function handlePlayerAdd(player: Player) {
    let updatedPlayers = [...playersState, ...[player]];
    setPlayersState(updatedPlayers);
  }

  function handlePlayerDrop(player: Player) {
    let droppedPlayer = {
      ...player,
      ...{
        dropped: true,
      },
    };

    let otherPlayers = playersState.filter((p) => p.id !== droppedPlayer.id);
    let updatedPlayers = [...otherPlayers, ...[droppedPlayer]];

    setPlayersState(updatedPlayers);
  }

  function handleSaveTournament() {
    if (hasDuplicatePlayers) return;
    if (!hasEnoughPlayers) return;

    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: playersState,
        rounds: appState.tournament.rounds,
      },
    });

    resetStates();
  }

  function handleDiscardTournament() {
    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });

    resetStates();
  }

  function resetStates() {
    setPlayersState(appState.tournament.players);
  }

  return render();
};
