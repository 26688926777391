import { ResistanceHelper } from "../persistence/helpers/ResistanceHelper";
import { Player } from "../persistence/model/Player";
import { findDuplicates } from "./ArrayUtils";

export function mergeSortPlayers(arr: Player[]): Player[] {
  if (arr.length <= 1) {
    return arr;
  }

  const middle = Math.floor(arr.length / 2);
  const left = arr.slice(0, middle);
  const right = arr.slice(middle);

  return mergePlayers(mergeSortPlayers(left), mergeSortPlayers(right));
}

function mergePlayers(left: Player[], right: Player[]): Player[] {
  let result: Player[] = [];
  let leftIndex = 0;
  let rightIndex = 0;

  while (leftIndex < left.length && rightIndex < right.length) {
    let leftRes = ResistanceHelper.calculateResistance(left[leftIndex].score);
    let rightRes = ResistanceHelper.calculateResistance(right[rightIndex].score);
    if (leftRes < rightRes) {
      result.push(right[rightIndex]);
      rightIndex++;
    } else {
      result.push(left[leftIndex]);
      leftIndex++;
    }
  }

  return result.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
}

export function getDuplicatePlayerNames(players: Map<string, string>) {
  return findDuplicates(Array.from(players.values()));
}

export function getPlayerAmount(players: Map<string, string>) {
  return Array.from(players.values()).filter((n) => n !== "").length;
}