import { Player } from "../model/Player";
import { Round } from "../model/Round";
import { Score } from "../model/Score";

// Score values from :
// https://www.pokemon.com/static-assets/content-assets/cms2/pdf/play-pokemon/rules/play-pokemon-tournament-rules-handbook-10062023-en.pdf
export class ScoreProperties {
  static readonly RESISTANCE_VALUE_WIN = 3;
  static readonly RESISTANCE_VALUE_LOSE = 0;
  static readonly RESISTANCE_VALUE_DRAW = 1;
}

export class ScoreHelper {
  public static create(): Score {
    return {
      win: 0,
      lose: 0,
      draw: 0,
      bye: 0,
    };
  }

  public static calculateScore(rounds: Round[], player: Player): Score {
    let allMatches = rounds.flatMap((r) => r.matches);
    let allByes = rounds.flatMap((r) => r.byes);

    let playerMatchIds = player.history.map((h) => h.matchId);
    let playerMatches = allMatches.filter((m) => playerMatchIds.includes(m.id));

    let playerWins = playerMatches.filter((m) => m.winnerId === player.id).length;
    let playerLosses = playerMatches.filter((m) => m.loserId === player.id).length;
    let playerByes = allByes.filter((b) => b === player.id).length;
    let playerDraws = playerMatches.length - playerWins - playerLosses;

    return {
      win: playerWins + playerByes,
      lose: playerLosses,
      draw: playerDraws,
      bye: playerByes,
    };
  }

  public static calculateRounds(score: Score): number {
    return score.win + score.lose + score.draw;
  }

  public static calculatePoints(score: Score): number {
    let total = 0;
    total += score.win * ScoreProperties.RESISTANCE_VALUE_WIN;
    total += score.lose * ScoreProperties.RESISTANCE_VALUE_LOSE;
    total += score.draw * ScoreProperties.RESISTANCE_VALUE_DRAW;
    return total;
  }
}
