import { ReactElement } from "react";
import { Player } from "../../../../persistence/model/Player";

interface Props {
  className: any;
  index: number;
  player: Player;
  onPlayerRemove: (player: Player) => void;
}

export const PlayersEntry = (props: Props): ReactElement => {

  let stateClassName = props.player.dropped ? "greyed-out" : "entry-remove"

  return (
    <div className={`entry ${props.className}`}>
      <div className={`entry-number ${stateClassName}`}>{props.index + 1}</div>
      <div className={`entry-player ${stateClassName}`}>
        <span className={`entry-player-name ${stateClassName}`}>{props.player.name}</span>
        {renderDropOrDropped(props)}
      </div>
    </div>
  );

  function renderDropOrDropped(props: Props): ReactElement {
    if (props.player.dropped) {
      return <span
        className={`entry-remove ${stateClassName}`}>
        Dropped
      </span>
    } else {
      return <span
        className="entry-remove link edit"
        onClick={() => props.onPlayerRemove(props.player)}>
        Drop
      </span>
    }
  }
};