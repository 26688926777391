import { ChangeEvent, ReactElement } from "react";
import { IoClose } from "react-icons/io5";

interface Props {
  className: any;
  index: number;
  id: string;
  name: string;
  onValueChange: (id: string, name: string) => void;
  onValueRemove: (id: string) => void;
}

export const PlayersInput = (props: Props): ReactElement => {
  
  const handleNewPlayerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newName = e.target.value;
    props.onValueChange(props.id, newName);
  };

  return (
    <div className={`entry ${props.className}`}>
      <div className={`entry-number `}>{props.index + 1}</div>
      <div className="entry-player">
        <input
          className={`entry-player-name ${props.className}`}
          type="text"
          placeholder="Add Player"
          value={props.name}
          onChange={handleNewPlayerNameChange}
        />

        {props.name.length > 0 && (
          <span className={`entry-remove icon link ${props.className}`} onClick={(): void => props.onValueRemove(props.id)}>
            <IoClose />
          </span>
        )}
      </div>
    </div>
  );
};
