import { ReactElement, useState } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { PlayersInput } from "./PlayersInput";
import { RoundHelper } from "../../../persistence/helpers/RoundHelper";
import { IoChevronBack } from "react-icons/io5";
import { PlayerHelper } from "../../../persistence/helpers/PlayerHelper";
import { uuid } from "../../../utils/UuidUtils";
import { MapUtils } from "../../../utils/MapUtils";
import { getDuplicatePlayerNames, getPlayerAmount } from "../../../utils/PlayerArrayUtils";

export const PlayersAddView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const [playerNamesState, setPlayerNamesState] = useState<Map<string, string>>(
    updateEmptyInputField(new Map(appState.tournament.players.map((p) => [uuid(), p.name])))
  );

  let hasEnoughPlayers = getPlayerAmount(playerNamesState) >= PlayerHelper.PLAYER_AMOUNT_MINIMUM;
  let duplicatePlayerNames = getDuplicatePlayerNames(playerNamesState);
  let hasDuplicatePlayers = duplicatePlayerNames.length > 0;

  function render(): ReactElement {
    return (
      <div className="page bg-players">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="players">
                Add
                <br />
                Players
              </h1>
            </div>
            <div className="subnavigation double">
              <div className="subnav-button alert link" onClick={handleBackButton}>
                <IoChevronBack /> Back
              </div>
              {renderInformationMessage()}
            </div>
          </div>
          <div className="entries">{renderPlayerNameInputs()}</div>
        </div>
        <div className="bottom-navigation single">{renderStartButton()}</div>
      </div>
    );
  }

  function renderInformationMessage() {
    if (hasDuplicatePlayers) {
      let message = "Use unique names";
      return <div className="subnav-button greyed-out">{message}</div>;
    } else if (!hasEnoughPlayers) {
      let message = "Add more players";
      return <div className="subnav-button greyed-out">{message}</div>;
    } else {
      let rounds = RoundHelper.getExpectedRounds(playerNamesState.size);
      let message = `Tournament will be ${rounds} rounds`;
      return <div className="subnav-button navigation">{message}</div>;
    }
  }

  function renderPlayerNameInputs() {
    let inputs: ReactElement[] = [];
    let i = 0;
    playerNamesState.forEach((name, id) => {
      let lastElementStyle = i >= playerNamesState.size - 1 ? "next" : "";
      let duplicateNameStyle = duplicatePlayerNames.includes(name) ? "alert" : "";

      inputs.push(
        <PlayersInput
          className={`${duplicateNameStyle} ${lastElementStyle}`}
          index={i}
          key={id}
          id={id}
          name={name}
          onValueChange={handleNameValueChanged}
          onValueRemove={handlePlayerRemove}
        />
      );
      i++;
    });
    return inputs;
  }

  function renderStartButton() {
    let className = `link greyed-out `;

    if (!hasDuplicatePlayers && hasEnoughPlayers) className += "next";

    return (
      <h1 className={className} onClick={handleSubmitPlayers}>
        <span className="preheader">Round 1</span>
        <br />
        Pairings
      </h1>
    );
  }

  function handleNameValueChanged(id: string, name: string) {
    let updatedNames = new Map(playerNamesState.set(id, name));
    updatedNames = updateEmptyInputField(updatedNames);
    setPlayerNamesState(updatedNames);
  }

  function handlePlayerRemove(id: string) {
    let updatedNames = new Map(playerNamesState);
    updatedNames.delete(id);
    updatedNames = updateEmptyInputField(updatedNames);
    setPlayerNamesState(updatedNames);
  }

  function updateEmptyInputField(map: Map<string, string>) {
    let emptyFieldCount = Array.from(map.values()).filter((n) => n === "").length;
    let updatedMap = new Map(map);
    if (emptyFieldCount <= 0) {
      updatedMap.set(uuid(), "");
    } else if (emptyFieldCount > 1) {
      updatedMap = MapUtils.filter(updatedMap, (_, name) => name !== "");
      updatedMap.set(uuid(), "");
    }
    return updatedMap;
  }

  function handleSubmitPlayers() {
    if (hasDuplicatePlayers) return;
    if (!hasEnoughPlayers) return;

    let players = PlayerHelper.createPlayers(playerNamesState);

    setAppState({
      state: States.ROUND_PROPOSE,
      tournament: {
        players: players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  function handleBackButton() {
    setAppState({
      state: States.TOURNAMENT_CREATE,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
