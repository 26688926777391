import { ReactElement } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { RoundHelper } from "../../../persistence/helpers/RoundHelper";
import { MatchEntry } from "./match-entry/MatchEntry";
import { ByeEntry } from "./bye-entry/ByeEntry";
import { RoundEditButton } from "../../dialogs/round-edit/RoundEditButton";
import { PlayerEditButton } from "../../dialogs/player-edit/PlayerEditButton";
import { EndTournamentButton } from "../../dialogs/end-tournament/EndTournamentButton";

export const RoundProposeView = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  const round = RoundHelper.next(appState.tournament);
  const roundNumber = RoundHelper.getCurrentRoundNumber(appState.tournament) + 1;

  function render(): ReactElement {
    return (
      <div className="page bg-pairings">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1 className="pairings">
                <span className="preheader">Round {roundNumber}</span>
                <br />
                <span>Pairings</span>
              </h1>
            </div>
            <div className="subnavigation double">
              <RoundEditButton currentState={States.ROUND_PROPOSE} />
              {renderPlayerEditButton()}
            </div>
          </div>
          <div className="entries">
            {renderMatches()}
            {renderByes()}
          </div>
        </div>
        <div className="bottom-navigation double">
          <EndTournamentButton className="subnav-button alert link" showDialog={true} />
          <h1 className="next link impatient" onClick={handleStartRound}>
            Start Round {roundNumber}
          </h1>
        </div>
      </div>
    );
  }

  function renderPlayerEditButton() {
    let roundOne = appState.tournament.rounds.length <= 0;
    if (roundOne) {
      return (
        <div className="subnav-button navigation link" onClick={goToPlayerAddView}>
          Edit players
        </div>
      );
    } else {
      return <PlayerEditButton />;
    }
  }

  function renderMatches() {
    return round.matches.map((match, i) => {
      let table = i + 1;
      return <MatchEntry key={match.id} className="pairings-entry-instance" table={table} match={match} />;
    });
  }

  function renderByes() {
    return round.byes.map((b, i) => {
      let table = round.matches.length + 1 + i;
      return <ByeEntry key={b} className="pairings-entry-instance" table={table} bye={b} />;
    });
  }

  function handleStartRound(): void {
    setAppState({
      state: States.ROUND_PLAYING,
      tournament: {
        players: appState.tournament.players,
        rounds: [...appState.tournament.rounds, ...[round]],
      },
    });
  }

  function goToPlayerAddView() {
    setAppState({
      state: States.PLAYERS_ADD,
      tournament: {
        players: appState.tournament.players,
        rounds: appState.tournament.rounds,
      },
    });
  }

  return render();
};
