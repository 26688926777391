import { ChangeEvent, ReactElement, useState } from "react";
import { Player } from "../../../../persistence/model/Player";
import { PlayerHelper } from "../../../../persistence/helpers/PlayerHelper";
import { uuid } from "../../../../utils/UuidUtils";

interface Props {
  className: any;
  index: number;
  players: Player[];
  onPlayerAdd: (player: Player) => void;
}

export const PlayersInput = (props: Props): ReactElement => {
  const [newPlayerNameState, setNewPlayerNameState] = useState<string>("");

  const handleNewPlayerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newName = e.target.value;
    setNewPlayerNameState(newName);
  };

  let playerNames = props.players.map((p) => p.name);
  let duplicateName = playerNames.includes(newPlayerNameState);
  let duplicateStyle = duplicateName ? "alert" : "next";

  return (
    <div className={`entry ${props.className} ${duplicateStyle}`}>
      <div className={`entry-number ${duplicateStyle}`}>{props.index + 1}</div>
      <div className={`entry-player ${duplicateStyle}`}>
        <div className={`${duplicateStyle}`}>
          <input type="text" placeholder="Add Player" value={newPlayerNameState} onChange={handleNewPlayerNameChange} />
        </div>
        <span className={`entry-add icon link ${duplicateStyle}`} onClick={handlePlayerAdd}>
          Add
        </span>
      </div>
    </div>
  );

  function handlePlayerAdd() {
    if (!newPlayerNameState) return;
    if (duplicateName) return;

    let newPlayer = PlayerHelper.create(newPlayerNameState, uuid(), true);
    props.onPlayerAdd(newPlayer);
    setNewPlayerNameState("");
  }
};
